<!--
 * @Description: 登录
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-14 19:43:02
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/login/index.vue
-->
<template>
  <div>
    <div class="login-view">
      <div class="login-image"></div>
      <div class="login-right">
        <!-- 代理商 -->
        <div v-if="loginStatus === 3" class="login-form">
          <div class="form-title">成为代理商</div>
          <div class="form-text" style="margin-bottom: 40px">
            请填写正确信息
          </div>
          <div class="form-input">
            <input type="text" placeholder="请输入您的姓名" />
          </div>
          <div class="form-input">
            <input type="text" placeholder="请输入您的联系方式" />
          </div>
          <div class="form-input">
            <input type="text" placeholder="请输入您的公司名称" />
          </div>
          <div class="form-input">
            <input
              type="text"
              placeholder="您的申请区域(请以省份为单位进行申请)"
            />
          </div>
          <div class="form-textarea">
            <textarea
              type="textarea"
              placeholder="请简单描述您为什么适合做代理，以及需要咨询的问题"
            ></textarea>
          </div>
          <div class="form-submit">提交</div>
        </div>
        <!-- 登录 -->
        <div class="login-form" v-else-if="loginStatus === 1">
          <div class="form-title">您好，欢迎登录</div>
          <div class="form-text" style="margin-bottom: 40px">
            请填写正确信息
          </div>
          <div class="form-input">
            <input
              type="text"
              v-model="user.username"
              placeholder="请输入您的帐号"
            />
          </div>
          <div class="form-input">
            <input
              type="password"
              v-model="user.password"
              placeholder="请输入您的密码"
            />
          </div>
          <div
            :class="['form-submit', { 'form-submit-none': !isUserDisplay }]"
            @click="isUserDisplay ? handleLogon() : null"
          >
            立即登录
          </div>
          <div class="form-forgot-password" @click="loginStatus = 2">
            忘记密码
          </div>
        </div>
        <!-- 修改密码 -->
        <div v-else-if="loginStatus === 2" class="login-form">
          <div class="form-title">修改密码</div>
          <div class="form-text" style="margin-bottom: 40px">
            请重新设定您的登录密码
          </div>
          <div class="form-input">
            <input
              type="text"
              v-model="edit.mobile"
              placeholder="请输入您的手机号"
            />
          </div>
          <div class="form-input">
            <input type="text" v-model="edit.code" placeholder="请输入验证码" />
            <div v-if="smsFlag" class="form-code">{{ sendTime }}</div>
            <div v-else class="form-code" @click="getSend">
              {{ sendTime }}
            </div>
          </div>
          <div class="form-input">
            <input
              type="password"
              v-model="edit.password"
              placeholder="请输入新密码"
            />
          </div>
          <div class="form-input">
            <input
              type="password"
              v-model="edit.repeatPassword"
              placeholder="请再次输入您的新密码"
            />
          </div>

          <div
            :class="['form-submit', { 'form-submit-none': !isEditDisplay }]"
            @click="isEditDisplay ? handleEdit() : null"
          >
            立即修改
          </div>
          <div class="form-forgot-password" @click="loginStatus = 1">
            返回登录
          </div>
        </div>
      </div>

      <div class="login-logo" @click="goPages('Index')">
        <img src="../../../assets/index/logo.png" />
      </div>

      <!-- 成功 -->
      <div
        class="negative-popup"
        v-if="successState"
        @click="successState = false"
      >
        <div class="negative" @click.stop>
          <div class="negative-icon">
            <img src="../../../assets/icon/success.png" alt="" />
          </div>
          <div class="negative-text">密码重置成功，请重新登录</div>
          <div class="negative-close" @click="successState = false">
            <img src="../../../assets/icon/close.png" alt="" />
          </div>
        </div>
      </div>

      <!-- 失败 -->
      <div class="negative-popup" v-if="error.show" @click="error.show = false">
        <div class="negative" @click.stop>
          <div class="negative-icon">
            <img src="../../../assets/icon/error.png" alt="" />
          </div>
          <div class="negative-text">{{ error.text }}</div>
          <div class="negative-close" @click="error.show = false">
            <img src="../../../assets/icon/close.png" alt="" />
          </div>
        </div>
      </div>

      <!-- 第一次登录修改密码 -->
      <div
        class="password-popup"
        v-if="passwordState"
        @click="passwordState = false"
      >
        <div class="password" @click.stop>
          <div class="password-form">
            <div class="login-form">
              <div class="form-title">重置密码</div>
              <div class="form-text" style="margin-bottom: 40px">
                首次登录请重置您的登录密码
              </div>
              <div class="form-input">
                <input
                  type="text"
                  v-model="edit.mobile"
                  placeholder="请输入您的手机号"
                />
              </div>
              <div class="form-input">
                <input
                  type="text"
                  v-model="edit.code"
                  placeholder="请输入验证码"
                />
                <div v-if="smsFlag" class="form-code">{{ sendTime }}</div>
                <div v-else class="form-code" @click="getSend">
                  {{ sendTime }}
                </div>
              </div>
              <div class="form-input">
                <input
                  type="password"
                  v-model="edit.password"
                  placeholder="请输入新密码"
                />
              </div>
              <div class="form-input">
                <input
                  type="password"
                  v-model="edit.repeatPassword"
                  placeholder="请再次输入您的新密码"
                />
              </div>

              <template v-if="role === 'customer'">
                <div class="form-text" style="margin-top: 30px">
                  请补全公司信息(用于视频生产，请认真填写)
                </div>
                <div class="form-input">
                  <input
                    type="text"
                    v-model="edit.company_industry"
                    placeholder="公司行业"
                  />
                </div>
                <div class="form-input">
                  <input
                    type="text"
                    v-model="edit.company_shortname"
                    placeholder="公司简称"
                  />
                </div>
                <div class="form-input">
                  <input
                    type="text"
                    v-model="edit.brand"
                    placeholder="品牌名"
                  />
                </div>
                <div class="form-input">
                  <input
                    type="text"
                    v-model="edit.business_mobile"
                    placeholder="留资电话"
                  />
                </div>
              </template>

              <div
                :class="[
                  'form-submit',
                  {
                    'form-submit-none': !(role === 'customer'
                      ? isResettingDisplay
                      : isResettingDisplayOperation),
                  },
                ]"
                @click="
                  (
                    role === 'customer'
                      ? isResettingDisplay
                      : isResettingDisplayOperation
                  )
                    ? handleEdit()
                    : null
                "
              >
                立即修改
              </div>
              <div class="form-privacy">
                <el-checkbox v-model="checked"></el-checkbox> 同意<span
                  @click="handlePrivacy"
                  >《隐私条款》</span
                >
              </div>
              <!-- <div class="form-forgot-password" @click="loginStatus = 1">
            返回登录
          </div> -->
            </div>
          </div>

          <div class="password-close" @click="passwordState = false">
            <img src="../../../assets/icon/close.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <Filings />
  </div>
</template>

<script>
import { HTTP } from "../../../utils/request";
// eslint-disable-next-line no-unused-vars
import { setCookie, setToken } from "../../../utils/common";
// eslint-disable-next-line no-unused-vars
import router from "../../../router";
import {
  // eslint-disable-next-line no-unused-vars
  clientRouter,
  // eslint-disable-next-line no-unused-vars
  clientList,
  // eslint-disable-next-line no-unused-vars
  serviceRouter,
  // eslint-disable-next-line no-unused-vars
  serviceList,
} from "../../../utils/menu";

const URL = {
  login: "api/common/login",
  smsCode: "api/common/smsCode",
  resetPassword: "api/common/resetPassword",
  info: "api/customer/user/info",
  operationInfo: "/api/operation/user/info",
};

export default {
  data() {
    return {
      successState: false,
      error: {
        show: false,
        text: "",
      },
      passwordState: false,
      loginStatus: 1, // 1:登录 2:修改密码 3:供应商
      user: {
        username: "",
        password: "",
      },
      edit: {
        mobile: "",
        code: "",
        password: "",
        repeatPassword: "",
        company_industry: "",
        company_shortname: "",
        brand: "",
        business_mobile: "",
      },
      isRead: false,
      sendTime: "获取验证码",
      snsMsgWait: 60,
      smsFlag: false,
      checked: false,
      role: "",
    };
  },
  computed: {
    isUserDisplay() {
      return !!this.user.username && !!this.user.password;
    },
    isEditDisplay() {
      return (
        !!this.edit.mobile &&
        !!this.edit.code &&
        !!this.edit.password &&
        !!this.edit.repeatPassword
      );
    },
    isResettingDisplay() {
      return (
        !!this.edit.mobile &&
        !!this.edit.code &&
        !!this.edit.password &&
        !!this.edit.repeatPassword &&
        !!this.edit.company_industry &&
        !!this.edit.company_shortname &&
        !!this.edit.business_mobile &&
        this.checked
      );
    },
    isResettingDisplayOperation() {
      return (
        !!this.edit.mobile &&
        !!this.edit.code &&
        !!this.edit.password &&
        !!this.edit.repeatPassword &&
        this.checked
      );
    },
  },
  methods: {
    goPages(name) {
      this.$router.push({
        name,
      });
    },
    async handleLogon() {
      const {
        tokenData: { token },
        // eslint-disable-next-line no-unused-vars
        role,
      } = await HTTP({
        url: URL.login,
        data: {
          ...this.user,
        },
      });
      setToken(token);

      const code = await this.getInfo(role);
      if (code === 410) {
        this.role = role;
        this.passwordState = true;
      } else {
        // router.addRoutes();
        if (role === "customer") {
          // router.addRoutes(clientRouter);
          setCookie("menu_list", clientList);
          // setCookie("router_state", "customer");
          this.$router.push({
            name: "Staging",
          });
        } else if (role === "operation") {
          // router.addRoutes(serviceRouter);
          setCookie("menu_list", serviceList);
          // setCookie("router_state", "operation");
          this.$router.push({
            name: "Clue",
          });
        }
        setCookie("role", role);
      }
    },

    // 短信验证码
    async getSend() {
      let _this = this;

      let data = {
        mobile: _this.edit.mobile,
      };

      if (!data.mobile) {
        this.$message.error("手机号不能为空");
        return;
      }

      if (!/^1[3456789]\d{9}$/.test(data.mobile)) {
        this.$message.error("请输入正确的手机号");
        return;
      }

      await HTTP({
        url: URL.smsCode,
        data: {
          scene: 3,
          mobile: data.mobile,
        },
      });

      this.$message.success("验证码发送成功");

      const inter = setInterval(() => {
        _this.smsFlag = true;
        _this.sendTime = _this.snsMsgWait + "s";
        _this.snsMsgWait = _this.snsMsgWait - 1;
        if (_this.snsMsgWait < 0) {
          clearInterval(inter);
          _this.sendTime = "获取验证码";
          _this.snsMsgWait = 60;
          _this.smsFlag = false;
        }
      }, 1000);
    },

    async handleEdit() {
      const { code, message } = await HTTP(
        {
          url: URL.resetPassword,
          data: {
            ...this.edit,
          },
        },
        3
      );
      if (code === 200) {
        this.successState = true;
        this.user = {
          username: this.edit.mobile,
          password: "",
        };
        this.loginStatus = 1;
        this.passwordState = false;
      } else {
        this.error = {
          show: true,
          text: message,
        };
      }
    },

    async getInfo(role) {
      const code = await HTTP(
        {
          url: URL[role === "operation" ? "operationInfo" : "info"],
          method: "get",
        },
        2
      );
      return code;
    },

    handlePrivacy() {
      window.open("http://www.zealytech.com/#/privacy");
    },
  },
};
</script>

<style lang="scss" scoped>
.login-view {
  width: 100%;
  height: calc(100vh - 50px);
  background-color: #fff;
  display: flex;
  align-items: center;
  .login-image {
    width: 50%;
    height: 100%;
    background-image: url("https://aboostify.oss-cn-hangzhou.aliyuncs.com/workstation/system/image/common_background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
  }
  .login-right {
    width: 50%;
  }
  .login-form {
    width: 418px;
    margin: 0 auto;
    .form-title {
      font-size: 36px;
      font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
      font-weight: bold;
      color: #000000;
      line-height: 50px;
    }
    .form-text {
      font-size: 20px;
      font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
      font-weight: 400;
      color: #999999;
      line-height: 30px;
      margin-top: 6px;
      // margin-bottom: 40px;
    }
    .form-input {
      height: 48px;
      border: 1px solid #d9e0ed;
      border-radius: 4px;
      background-color: #f6f9ff;
      margin-top: 15px;
      display: flex;
      align-items: center;
      input {
        flex: 1;
        height: 100%;
        border: none;
        border-radius: 4px;
        padding: 0 10px;
        font-size: 15px;
        background-color: #f6f9ff;
      }
      .form-code {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #ee8031;
        margin: 0 10px;
        cursor: pointer;
      }
    }
    .form-textarea {
      height: 125px;
      border: 1px solid #d9e0ed;
      border-radius: 4px;
      background-color: #f6f9ff;
      margin-top: 15px;
      textarea {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 4px;
        font-size: 15px;
        background-color: #f6f9ff;
        padding: 10px;
      }
    }
    .form-submit {
      width: 418px;
      height: 52px;
      background: linear-gradient(136deg, #ffa250 0%, #ff7800 100%);
      border-radius: 8px 8px 8px 8px;
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #ffffff;
      cursor: pointer;
    }
    .form-submit-none {
      opacity: 0.7;
      cursor: no-drop;
    }
    .form-forgot-password {
      width: 418px;
      text-align: center;
      font-size: 16px;
      font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
      font-weight: 400;
      color: #999999;
      line-height: 30px;
      margin-top: 14px;
      cursor: pointer;
    }
  }
}

.login-logo {
  width: 133px;
  height: 38px;
  overflow: hidden;
  position: fixed;
  top: 20px;
  left: calc(50% + 20px);
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}

.negative-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  .negative {
    width: 484px;
    height: 345px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-icon {
      width: 88px;
      height: 88px;
      margin: auto {
        top: 92px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-text {
      font-size: 28px;
      padding: 0 20px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #000000;
      line-height: 30px;
      text-align: center;
      margin-top: 42px;
    }
    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.password-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  .password {
    width: 684px;
    height: 645px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    display: flex;
    // align-items: center;
    justify-content: center;

    .password-form {
      width: 100%;
      overflow-y: auto;
      padding: 50px 0;
    }

    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.form-privacy {
  font-size: 13px;
  color: #333333;
  text-align: center;
  margin-top: 20px;
  span {
    cursor: pointer;
    color: #0066fe;
  }
}
</style>
